import React from 'react'
import './legal.scss';


function Faqs() {
  return (
    <div className="buddy-page" >
      <div className="legal_hero_wrap">
        <h2 className="legal_header">Got a Question ? <br /> We've got Answers </h2>
        
      </div>

    
      <div className="legal_box_wrap" style={{paddingBottom:"100px"}}>

    {/*  <label for="panel_1">No</label>
      <input type="radio" name="panel" id="panel_1" />
      <div className="collapsible">
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus pellentesque consectetur nisi. Vivamus lacinia tempus laoreet. Pellentesque vehicula sollicitudin orci, in convallis nibh aliquam ut. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean facilisis metus at velit consectetur commodo. Nunc dolor nibh, cursus quis accumsan faucibus, viverra bibendum magna. Sed id convallis nisi. Quisque a orci sed ligula pellentesque pellentesque. Integer molestie, enim sit amet sodales pharetra, nunc risus gravida dolor, quis placerat elit erat sagittis justo. Nulla nec lacus varius, tempus ipsum in, rutrum purus. Sed ornare neque nec leo suscipit eleifend. Maecenas in ante at lorem ultrices lobortis. Morbi malesuada, nisi ac ornare dignissim, lacus dui euismod ipsum, et porttitor velit leo quis lacus. Suspendisse potenti.</p>
      </div>
      <label for="panel_2">JS</label>

      <input type="radio" name="panel" id="panel_2" />

      <div className="collapsible">
        <h3>With Scrolling</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus pellentesque consectetur nisi. Vivamus lacinia tempus laoreet. Pellentesque vehicula sollicitudin orci, in convallis nibh aliquam ut. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean facilisis metus at velit consectetur commodo. Nunc dolor nibh, cursus quis accumsan faucibus, viverra bibendum magna. Sed id convallis nisi. Quisque a orci sed ligula pellentesque pellentesque. Integer molestie, enim sit amet sodales pharetra, nunc risus gravida dolor, quis placerat elit erat sagittis justo. Nulla nec lacus varius, tempus ipsum in, rutrum purus. Sed ornare neque nec leo suscipit eleifend. Maecenas in ante at lorem ultrices lobortis. Morbi malesuada, nisi ac ornare dignissim, lacus dui euismod ipsum, et porttitor velit leo quis lacus. Suspendisse potenti.</p>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus pellentesque consectetur nisi. Vivamus lacinia tempus laoreet. Pellentesque vehicula sollicitudin orci, in convallis nibh aliquam ut. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean facilisis metus at velit consectetur commodo. Nunc dolor nibh, cursus quis accumsan faucibus, viverra bibendum magna. Sed id convallis nisi. Quisque a orci sed ligula pellentesque pellentesque. Integer molestie, enim sit amet sodales pharetra, nunc risus gravida dolor, quis placerat elit erat sagittis justo. Nulla nec lacus varius, tempus ipsum in, rutrum purus. Sed ornare neque nec leo suscipit eleifend. Maecenas in ante at lorem ultrices lobortis. Morbi malesuada, nisi ac ornare dignissim, lacus dui euismod ipsum, et porttitor velit leo quis lacus. Suspendisse potenti.</p>
      </div>
      <label for="panel_3">Accordion</label>
      <input type="radio" name="panel" id="panel_3" checked />
      <div className="collapsible">
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus pellentesque consectetur nisi. Vivamus lacinia tempus laoreet. Pellentesque vehicula sollicitudin orci, in convallis nibh aliquam ut. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean facilisis metus at velit consectetur commodo. Nunc dolor nibh, cursus quis accumsan faucibus, viverra bibendum magna. Sed id convallis nisi. Quisque a orci sed ligula pellentesque pellentesque. Integer molestie, enim sit amet sodales pharetra, nunc risus gravida dolor, quis placerat elit erat sagittis justo. Nulla nec lacus varius, tempus ipsum in, rutrum purus. Sed ornare neque nec leo suscipit eleifend. Maecenas in ante at lorem ultrices lobortis. Morbi malesuada, nisi ac ornare dignissim, lacus dui euismod ipsum, et porttitor velit leo quis lacus. Suspendisse potenti.</p>
      </div>
      
         */}

      
<div class="block">
  <div>
    <input type="radio" name="item" id="item-one" checked hidden />
    <label for="item-one">What is Wealthbuddy?</label>
    <div>
          <p>  Wealthbuddy is your one stop financial partner.
            In 4 clicks you can put your money to work yielding the 
            highest interest-output value for any amount spent.
          </p>
    </div>
  </div>
  <div>
    <input type="radio" name="item" id="item-two" hidden />
    <label for="item-two">Is Wealthbuddy by Meristem Licensed?</label>
    <div>
        <p> Meristem is registered with the Securities and Exchange
          Commission (SEC) of Nigeria and all our activities are
          in full compliance with regulatory requirements to the protection of our clients.

     </p>
    </div>
  </div>
  <div>
    <input type="radio" name="item" id="item-three" hidden />
    <label for="item-three">I have a problem with the password interface.</label>
    <div>
      <p> Password should contain UPPERCASE, lowercase, number, and Special character. Minimum of 6 characters (e.g., Wealthbuddy1@).</p>
    </div>
  </div>
  <div>
    <input type="radio" name="item" id="item-four" hidden />
    <label for="item-four"> Can I use security questions to recover my password? </label>
    <div>
      <p>This feature will be available on the upgraded wealthbuddy version soon. However, if you forget your password, kindly click on <b>forgot password</b> and follow the prompt to reset your password.</p>
    </div>
  </div>
          
  <div>
    <input type="radio" name="item" id="item-five" hidden />
    <label for="item-five"> Why do I need to add my KYC and BVN?</label>
    <div>
      <p> This is a regulatory requirement; it is mandatory to add your KYC and BVN to get your profile verified.</p>
    </div>
  </div>
  <div>
    <input type="radio" name="item" id="item-six" hidden />
    <label for="item-six">Is there a Touch ID/Face ID feature for security purposes?</label>
    <div>
      <p>This feature will be available on the upgraded Wealthbuddy version soon.</p>
    </div>
  </div>  
  
  <div>
    <input type="radio" name="item" id="item-seven" hidden />
    <label for="item-seven"> Is it compulsory to add my card before transacting? </label>
    <div>
      <p> The card option was put in place to allow funds reflect almost immediately. We are also PCI-DSS compliant, your card details are safe with us.</p>
    </div>
   </div>  
  <div>
    <input type="radio" name="item" id="item-eight" hidden />
    <label for="item-eight"> I want to save/invest without earning interest?</label>
    <div>
      <p> For Ethical Investors we have the option to uncheck ‘earning interest’ on all our Savings & Investment Products. You could also earn rental income from our MEEP product, designed for Ethical Investors. Click <a href="https://meristemng.com/product/wealth_management">here</a> to view our product papers.</p>
    </div>
  </div> 
  
  <div>
    <input type="radio" name="item" id="item-nine" hidden />
    <label for="item-nine"> I want to save/invest without earning interest?</label>
    <div>
      <p> For Ethical Investors we have the option to uncheck ‘earning interest’ on all our Savings & Investment Products. You could also earn rental income from our MEEP product, designed for Ethical Investors. Click here to view our product papers.</p>
    </div>
  </div>  

   <div>
    <input type="radio" name="item" id="item-ten" hidden />
    <label for="item-ten"> Can I transfer to a different bank account from the one I registered on the app.? </label>
    <div>
      <p> For security reasons, users are only allowed to liquidate their investments into bank accounts registered on the app. </p>
    </div>
  </div>   
  <div>
    <input type="radio" name="item" id="item-eleven" hidden />
    <label for="item-eleven"> Can I hide my account balance just in case I am in public?</label>
    <div>
      <p>This feature will be available on the upgraded Wealthbuddy version soon.</p>
    </div>
  </div> 
   
  <div>
    <input type="radio" name="item" id="item-twelve" hidden />
    <label for="item-twelve"> Can I buy Dollars on the app or convert Naira to Dollars on the app? </label>
    <div>
      <p>  Purchase of and conversion to Dollars is not available on the Wealthbuddy platform.</p>
    </div>
  </div>   

    <div>
    <input type="radio" name="item" id="item-thirteen" hidden />
    <label for="item-thirteen"> Can I save in foreign currency?  </label>
    <div>
      <p>  No, but you can invest in dollars with our MDIP (Meristem Dollar Investment Portfolio) Product. Minimum principal of $2,000 at 5% P.A at 180days.</p>
    </div>
  </div>  

  <div>
    <input type="radio" name="item" id="item-fourteen" hidden />
    <label for="item-fourteen"> Can I add to my savings any time before the expiration of the saving period? </label>
    <div>
      <p>  You can only increase/add to a savings plan that is not locked.</p>
    </div>
  </div>          
  
  <div>
    <input type="radio" name="item" id="item-fifteen" hidden />
    <label for="item-fifteen">  What rate of return do I earn on my savings? </label>
    <div>
      <p> Rate of returns vary across different savings products. This rate is available on individual savings plans before you create them </p>
    </div>
    </div>  
          
   <div>
    <input type="radio" name="item" id="item-sixteen" hidden />
    <label for="item-sixteen"> Are the investment plans on Wealthbuddy by Meristem Insured? What happens if there is a crash? </label>
    <div>
      <p> Yes, all investment and savings plans are insured.Wealthbuddy is built to the highest technology standards. This means it will not crash and your money is safe. </p>
    </div>
    </div>
          
    <div>
    <input type="radio" name="item" id="item-seventeen" hidden />
    <label for="item-seventeen">  Can I have multiple savings or investment on the same investment plan (e.g., two fixed lock savings plan)?</label>
    <div>
      <p> Yes, you can have multiple Savings and Investment plans (I.e., 2 Fixed Locked Savings, 2 Fixed Deposits). The only exception to this is the Mutual Funds Investment. To have another Mutual funds, you will have to create a Sub account.</p>
    </div>
  </div>
          
  <div>
    <input type="radio" name="item" id="item-eighteen" hidden />
    <label for="item-eighteen">  Where does my withdrawal go to?</label>
    <div>
      <p> Withdrawals are credited to the registered bank account on the Wealthbuddy platform for the user.</p>
    </div>
 </div>
          
  <div>
    <input type="radio" name="item" id="item-nineteen" hidden />
    <label for="item-nineteen">  What happens when my Savings Plan matures?</label>
    <div>
      <p> The total fund is credited to the wallet at maturity.</p>
    </div>
  </div>
          
  <div>
    <input type="radio" name="item" id="item-twenty" hidden />
    <label for="item-twenty">How long does the withdrawal process take on WealthBuddy?</label>
    <div>
      <p> The value date for varies depending on what product your funds are in across both savings and investments. But maximum withdrawal time is 24 working hours</p>
    </div>
  </div>
          
  <div>
    <input type="radio" name="item" id="item-twenty-one" hidden />
    <label for="item-twenty-one">  Are payments for subscription like Apple Music, True caller premium and Spotify available on Wealthbuddy?</label>
    <div>
      <p> Not available yet.</p>
    </div>
  </div>
          
  <div>
    <input type="radio" name="item" id="item-twenty-two" hidden />
    <label for="item-twenty-two"> Is there a dark mode feature on Wealthbuudy?</label>
    <div>
      <p> This feature will be available on the upgraded Wealthbuddy version soon. </p>
    </div>
  </div>


          
 

  


</div>

       
</div>
      

</div>



    
  )
}
export default Faqs;
