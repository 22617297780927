import React from 'react';
import file from '../../assets/img/file.svg';
import './legal.scss';

function Legal() {
  return (
    <div className="buddy-page">
      <div className="legal_hero_wrap">
        <div>
          <h2 className="legal_header">Our Products</h2>
          <p className="legal_text">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam.
          </p>
        </div>
        <div style={{ margin: '0 auto' }}>
          <img src={file} alt="" className="file_img" />
        </div>
      </div>

      <div className="legal_box_wrap">
        {/* info boxes */}
        <div className="info_box">
          <div className="info_top">
            <div className="circ" />
            <p className="circ_header">
              {' '}
              MUTUAL FUNDS{' '}
            </p>
          </div>
          <p className="info_body">
         
          MONEY MARKET FUND: The money market fund is a low risk fund, that invests in a combination of 
          fixed income instruments like treasury bills, commercial papers, fixed deposit and call placement.<br />

          EQUITY MARKET FUND: The equity market fund is a high-risk fund, that invests in equity instruments
          like stocks.<br /><br />

          Minimum Contribution::  NGN 10,000
          </p>
        </div>

        <div className="info_box">
          <div className="info_top">
            <div className="circ" />
            <p className="circ_header">
              {' '}
            
          FIXED TERM INVESTMENT PORTFOLIO (FIX-TIP){' '}
            </p>
          </div>
          <p className="info_body">
        The fixed-term investment account is a better alternative to a savings account, 
        especially for people looking to achieve set short-term projects. 
        It affords individuals the opportunity to set aside money and get higher interest than a 
        typical savings account in 30days. Are you looking to get a new phone? House? Or just a 
        better way to save? FIXTIP makes all that easy.<br /><br />

          Minimum Contribution:: NGN 200,000 
          </p>
        </div>

        <div className="info_box">
          <div className="info_top">
            <div className="circ" />
            <p className="circ_header">
              {' '}
            
          DOLLAR INVESTMENT PORTFOLIO{' '}
            </p>
          </div>
          <p className="info_body">
          This investment offers you a shield from currency risk. 
          It is a great way to spread your investment and reduce your risk exposure. 
          The Meristem Dollar investment creates a platform for you to earn interest in 
          foreign currency and invest in dollar denominated instruments. <br /><br />

          Minimum Contribution:: $2000 
          {/* and multiples of $500 after the first deposit */}
          </p>
        </div>

        <div className="info_box">
          <div className="info_top">
            <div className="circ" />
            <p className="circ_header">
              {' '}
              What makes Wealthbuddy different from others{' '}
            </p>
          </div>
          <p className="info_body">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud
          </p>
          <p className="read_more">Read More</p>
        </div>

        {/* info boxes end */}
      </div>
    </div>
  );
}
export default Legal;
