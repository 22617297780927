import React, { useContext } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import Header from './header/index';
import Home from '../views/home/index';
import Footer from './footer';
import { StateContext } from '../contextApi/index';
import Legal from '../views/legal/Legal';
import TermsConditions from '../views/terms_conditions/Terms_conditions';
import PrivacyNotice from '../views/privacy_notice/Privacy_notice';
import FAQs from '../views/faqs/Faqs'
import MobileMenu from './header/mobile';

const BankingLayout = () => {
  const [{ mobile }] = useContext(StateContext);
  return (
    <div className="buddy-page">
      <Router>
        <div className={`buddy-layout ${mobile === true && `mobile-active`}`}>
          <Header />
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/legal">
              <Legal />
            </Route>
            <Route exact path="/terms_conditions">
              <TermsConditions />
            </Route>
            <Route exact path="/privacy_notice">
              <PrivacyNotice />
            </Route>
            <Route exact path="/faq">
              <FAQs />
            </Route>
            <Redirect to="/" />
          </Switch>
          <Footer />
          {mobile === true && <MobileMenu />}
        </div>
      </Router>
    </div>
  );
};

export default BankingLayout;
