import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/modern-normalize.css';
import './assets/css/misc.css';
import './assets/css/style.css';
import './assets/css/media.css';
import BankingLayout from './layout';
import "./bootstrap";
import { StateProvider } from "./contextApi/index";

const initialState = {
    mobile: false
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'changeMobile':
            return {
                ...state,
                mobile: action.newMobile
            }
        default:
            return state;
    }
};

ReactDOM.render(
    <StateProvider initialState={initialState} reducer={reducer}>
        <BankingLayout />
    </StateProvider>, document.getElementById('root'));
