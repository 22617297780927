import React from 'react'
import axios from 'axios';
import { Component } from "react";

axios.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest',
    'Access-Control-Allow-Origin': '*',
    'crossorigin': 'true'
}

axios.defaults.credentials = 'same-origin';
axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}`;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.withCredentials = true

class MyApp extends Component {
    render() {
        const { Component, pageProps } = this.props
        return (
            <Component {...pageProps} />
        )
    }
}

export default MyApp