import React from "react";
import HeaderFold from "./sections/headerFold";
import Guide from "./sections/guide";
import MobileSolutions from "./sections/mobileSolutions";
import WebSolutions from "./sections/webSolutions";
import SavingsCta from "./sections/savings";
import Growth from "./sections/growth";


export default function Home() {
    return (
        <div className="buddy-entry--wrap">
            <HeaderFold />
            <Guide />
            <MobileSolutions />
            <WebSolutions />
            <SavingsCta />
            <Growth />
        </div>
    );
}
