import React, { useState } from 'react';
import Personal from '../../../assets/img/product-personal.svg';
import Fixed from '../../../assets/img/product-fixed.svg';
import GroupCon from '../../../assets/img/product-groupCon.svg';
import Flexible from '../../../assets/img/fixed-flexible.svg';
import './web.scss';

export default function SavingsCta() {
  const [savings, setSavings] = useState(true);
  return (
    <div
      id="product"
      className="buddy-standard--section buddy-section--100 padding-top--small"
    >
      <div className="container">
        <div className="buddy-section">
          <div className="buddy-center--section buddy-saving--section">
            <div className="buddy-section--100 buddy-cta--section">
              <div className="tab_wrap">
                <p
                  className={savings ? 'tab_text2' : 'tab_text'}
                  onClick={() => {
                    setSavings(true);
                  }}
                >
                  Savings
                </p>
                <p
                  onClick={() => {
                    setSavings(false);
                  }}
                  className={savings ? 'tab_text' : 'tab_text2'}
                >
                  Investments
                </p>
              </div>
              <div className="buddy-standard--wrap">
                {/* <h1 className="text-heading text-semi--bold heading-spacer">
                  {savings ? 'Savings Products' : 'Investments'}
                </h1> */}

                {/* INVESTMENT PRODUCTS */}
                {savings ? (
                  <div className="buddy-cta--items">
                    <div className="cta-item">
                      <h4 className="cta-item--heading">
                        <span>
                          <img src={Fixed} alt="Wealth Buddy Personal" />
                        </span>
                        Personal Target
                      </h4>
                      <p className="cta-item--body">
                        Choose from a list of targets and save regularly towards
                        your goals with great returns on your savings
                      </p>
                    </div>
                    <div className="cta-item">
                      <h4 className="cta-item--heading">
                        <span>
                          <img src={Personal} alt="Wealth Buddy Personal" />
                        </span>
                        Fixed Lock
                      </h4>
                      <p className="cta-item--body">
                        Put money away for a set period of time - minimum of 6
                        months, Only put money you can do without immediately as
                        you will not be able to access to it until maturity.
                      </p>
                    </div>
                    <div className="cta-item">
                      <h4 className="cta-item--heading">
                        <span>
                          <img src={Flexible} alt="Wealth Buddy Personal" />
                        </span>
                        Fixed Flexible
                      </h4>
                      <p className="cta-item--body">
                        Create a source of ready cash in case of an unplanned
                        expense and other unexpected things without having to
                        turn to debt.
                      </p>
                    </div>
                    <div className="cta-item">
                      <h4 className="cta-item--heading">
                        <span>
                          <img src={GroupCon} alt="Wealth Buddy Personal" />
                        </span>
                        Group Savings
                      </h4>
                      <p className="cta-item--body">
                        Make money with your friends and family (start a group
                        savings plan today.Group Target, Group Challenge and
                        Group Contributory)
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="buddy-cta--items">
                    <div className="cta-item">
                      <h4 className="cta-item--heading">Fixed Deposit</h4>
                      <p className="cta-item--body">
                        The fixed term investment account serves as a better
                        alternative to a savings account, especially for people
                        looking to achieve set short-term projects. It affords
                        individuals the opportunity to set aside money and get
                        higher interest than a typical savings account
                      </p>
                    </div>
                    <div className="cta-item">
                      <h4 className="cta-item--heading">Mutual Funds</h4>
                      <p className="cta-item--body">
                        The money market fund is a low risk fund, that invests
                        in a combination of fixed income instruments like
                        treasury bills, commercial papers, fixed deposit and
                        call placement.
                      </p>
                    </div>
                    <div className="cta-item">
                      <h4 className="cta-item--heading">Treasury Bills</h4>
                      <p className="cta-item--body">
                        Treasury Bills afford you the opportunity to lend money
                        to the government and get paid back with interest after
                        a period time usually 91days, 182 days or 364days
                        subject to your choice.
                      </p>
                    </div>
                    <div className="cta-item">
                      <h4 className="cta-item--heading">
                        Meristem Dollar Investment
                      </h4>
                      <p className="cta-item--body">
                        The Meristem Dollar investment creates a platform for
                        you to earn interest in foreign currency and invest in
                        dollar denominated instruments.
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
