import React from 'react';
import phoneMock from '../../../assets/img/savingsIllustration.svg';
import SavingsShapes from '../assets/shapes';
import DownloadBadges from '../../home/assets/downloadBadges';

export default function Growth() {
  return (
    <div className="buddy-standard--section buddy-section--100 growth-mock--section">
      <div className="container">
        <div className="buddy-section">
          <div className="buddy-section--50">
            <div className="buddy-standard--wrap">
              <h1 className="text-heading text-semi--bold heading-spacer">
                Let's Grow Wealth
                <br /> For You
              </h1>
              <DownloadBadges />
            </div>
          </div>
          <div className="buddy-section--50">
            <div className="buddy-saving--illustration">
              <img
                className="mock"
                src={phoneMock}
                alt={'Wealth Buddy Steps'}
              />
            </div>
          </div>
        </div>
      </div>
      <SavingsShapes />
    </div>
  );
}
