import React from 'react';
import entryImage from '../../../assets/img/Phone.png';

export default function HeaderFold() {
  return (
    <div className="buddy-entry--fold standard--bg buddy-section--100">
      <div className="container">
        <div className="buddy-section">
          <div className="buddy-section--50">
            <div className="entry-text--wrap">
              <h1 className="text-heading text-semi--bold">
                Money Management Reimagined.
              </h1>
              <p className="text-subheading">
                Because growing your wealth does not have to be a full time job
                for you. Let's grow wealth for you!
              </p>
              <div className="buddy-cta buddy-cta--lg">
                <a href="http://app.wealthbuddy.ng">Get started</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="fold-illustration">
        <img className="" src={entryImage} alt="Wealth Buddy landing" />
      </div>
    </div>
  );
}
