import React from 'react';
import MobileScreen1 from '../../../assets/img/mobileScreen1_.png';
import MobileScreen2 from '../../../assets/img/mobileScreen2_.png';
import MobileScreen3 from '../../../assets/img/green_front.png';
import {
  mobileSolutionsLeafLeft,
  mobileSolutionsCoinLeft,
  mobileSolutionsCoinLeftTwo,
  mobileSolutionsLeafRight,
  mobileSolutionsLeafRightTwo,
  mobileSolutionsCoinRight,
} from '../assets/imports';
import './web.scss';

export default function MobileSolutions() {
  return (
    <div className="buddy-standard--section buddy-section--100 padding-top--small">
      <div className="container">
        <div className="buddy-section">
          <div className="buddy-center--section">
            <div className="buddy-section--100">
              <div className="buddy-screens--wrap">
                <img
                  className="buddy-single--screen mob"
                  src={MobileScreen1}
                  alt={'Buddy Mobile Solutions'}
                />
                <img
                  className="buddy-single--screen mob2"
                  src={MobileScreen2}
                  alt={'Buddy Mobile Solutions'}
                />
                <img
                  className="buddy-single--screen mob3"
                  src={MobileScreen3}
                  alt={'Buddy Mobile Solutions'}
                />
              </div>
            </div>
            <div className="buddy-section--100">
              <div className="buddy-standard--wrap">
                <h1 className="text-heading text-semi--bold heading-spacer">
                  Mobile solution
                </h1>
                <p className="text-subheading">
                  We know that the best way to is not to pick the time you
                  invest, but to invest anytime you have cash. With the
                  Wealthbuddy Mobile app, you can always invest, no matter where
                  or when. In 4 clicks you are able to put your money to work
                  yielding the highest interest-output value for any amount
                  spent
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mobile-solutions--illustration">
        <span
          className="mobile-leaf-left"
          dangerouslySetInnerHTML={{ __html: mobileSolutionsLeafLeft }}
        ></span>
        <span
          className="mobile-coin-left animate-coin"
          dangerouslySetInnerHTML={{ __html: mobileSolutionsCoinLeft }}
        ></span>
        <span
          className="mobile-coin-left-two animate-coin"
          dangerouslySetInnerHTML={{ __html: mobileSolutionsCoinLeftTwo }}
        ></span>
        <span
          className="mobile-leaf-right"
          dangerouslySetInnerHTML={{ __html: mobileSolutionsLeafRight }}
        ></span>
        <span
          className="mobile-leaf-right-two"
          dangerouslySetInnerHTML={{ __html: mobileSolutionsLeafRightTwo }}
        ></span>
        <span
          className="mobile-coin-right animate-coin"
          dangerouslySetInnerHTML={{ __html: mobileSolutionsCoinRight }}
        ></span>
      </div>
    </div>
  );
}
