import React from 'react';
import file from '../../assets/img/file.svg';
import './legal.scss';
import { Link } from 'react-router-dom';

function Terms_Conditions() {
  return (
    <div className="buddy-page" >
      <div className="legal_hero_wrap">
        <div>
          <h2 className="legal_header">Terms & Conditions</h2>
          <p className="legal_text" style={{textAlign:"justify"}}> Please read these terms and conditions of use carefully before accessing, using or obtaining any materials, 
          information, products or services. </p>
        </div>
        <div style={{ margin: '0 auto' }}>
          <img src={file} alt="" className="file_img" />
        </div>
      </div>

      <div className="legal_box_wrap" style={{paddingBottom:"100px"}}>
        {/* info boxes */}
        <div>
          
          {/* <h2 className="legal_header">-</h2> */}
          <p className="legal_text" style={{width:"100%", textAlign:"justify"}}>
           By accessing the Wealthbuddy website, mobile or tablet application, 
          or any other feature or other Wealthbuddy platform (collectively, " Website” or “Wealthbuddy”), 
          you agree to be bound by these terms and conditions ("Terms") and our Privacy Policy.
          If you do not accept all of these Terms, then you may not use Our Website. 
          In these Terms, "we", "us", "our" and "Wealthbuddy" refers to Meristem Wealth Management Limited
          (the owners of Wealthbuddy), and "you" and "your" refers to you, the user of Our Website.<br /><br />
          We may modify these Terms for any reason—at any time—by posting a new version on Our Website; 
          these changes do not affect rights and obligations that arose prior to such changes.
          Your continued use of Our Website following the posting of modified Terms will be subject 
          to the Terms in effect at the time of your use. Please review these Terms periodically for changes.
          If you object to any provision of these Terms or any subsequent modifications to these Terms or 
          become dissatisfied with Our Website in any way, your only recourse is to immediately terminate
          use of Our Website.
          </p>
         
        </div>
        <br /><br />


        <div>
          <h3 className="legal_header">Availability</h3>
          <p className="legal_text" style={{width:"100%"}}>
          The Website is only available for use in Nigeria. 
          We make no representation that Wealthbuddy is appropriate or available for use outside Nigeria. 
          Similarly, we make no representations that accessing Wealthbuddy from locations outside Nigeria is 
          legal or permissible by local law. If you access Wealthbuddy from areas outside of Nigeria, 
          you do so at your own risk and you are solely responsible for compliance with local laws.

          </p>
        </div>
        <br /><br />

        <div>
          <h3 className="legal_header">User Registration</h3>
          <p className="legal_text" style={{width:"100%"}}>
          Wealthbuddy is an online savings and investment platform. 
          In order to access certain features of the Website, you must register to create an account 
          ("User Account"). When you register, you will be asked to provide information 
          (such as your date of birth and your Bank Verification Number), as well as your current 
          residential address, and/or any other KYC requirement as may be prescribed by 
          the Central Bank of Nigeria or Securities and Exchange Commissions from time to time, 
          for the opening of a Wealthbuddy account. These information shall be used for verification purposes.
          You will also be asked to choose a password, which you will be required to use to access your 
          User Account. Wealthbuddy has physical, electronic and procedural safeguards that comply with 
          regulatory standards to guard Users' and Clients' non-public personal information (see Privacy Policy).
          You are responsible for safeguarding your password and other User Account information.
          You agree not to disclose your password to any third party and you will notify Wealthbuddy immediately 
          if your password is lost or stolen or if you suspect any unauthorized use of your User Account. 
          As a User you agree that you shall be solely responsible for any activities or actions under your 
          User Account, whether or not you have authorized such activities or actions.
          You agree that the information you provide to us on account registration through our website or mobile
          applications will be true, accurate, current, and complete. 
          In order to ensure that we are able to provide communications to you electronically, 
          you must notify us of any change in your email address and your mobile device number or 
          other text message address by updating your profile on the Wealthbuddy app or website.

          </p>
        </div>

        <br /><br />
        {/* <div>
          { <h2 className="legal_header">Terms & Conditions</h2> }
          <p className="legal_text" style={{width:"100%"}}>
            Please read these terms and conditions of use carefully before accessing, using or obtaining any materials, 
          information, products or services. By accessing the Wealthbuddy website, mobile or tablet application, 
          or any other feature or other Wealthbuddy platform (collectively, " Website” or “Wealthbuddy”), 
          you agree to be bound by these terms and conditions ("Terms") and our Privacy Policy.
          If you do not accept all of these Terms, then you may not use Our Website. 
          In these Terms, "we", "us", "our" and "Wealthbuddy" refers to Meristem Wealth Management Limited
          (the owners of Wealthbuddy), and "you" and "your" refers to you, the user of Our Website.<br /><br />
          We may modify these Terms for any reason—at any time—by posting a new version on Our Website; 
          these changes do not affect rights and obligations that arose prior to such changes.
          Your continued use of Our Website following the posting of modified Terms will be subject 
          to the Terms in effect at the time of your use. Please review these Terms periodically for changes.
          If you object to any provision of these Terms or any subsequent modifications to these Terms or 
          become dissatisfied with Our Website in any way, your only recourse is to immediately terminate
          use of Our Website.

          </p>
        </div> */}

      

        <div  className="de">
          
          <h2 className="legal_header">Eligibilty</h2>
          <p className="legal_text" style={{width:"100%"}}>
            Before you can use our Website, you must: 
            <ol style={{listStyleType:"circle"}}>
              <li>Accept and agree to these Terms and Conditions;</li>
              <li>Register with us on the Wealthbuddy platform;</li>
              <li>Be a Nigerian citizen (or a legal Nigerian resident) of at least 18 years of age;</li>
              <li>Have a bank account with a Nigerian financial institution; and</li>
              <li>provide all information as may be requested by us, such as your name, email address, mobile device number, online credentials for your bank account, and such other information as we may request from time to time (collectively, “User Information”).</li>
            </ol>	

          </p>
         
        </div>
        <br /><br />

        <div>
          <h2 className="legal_header">Availability</h2>
          <p className="legal_text" style={{width:"100%"}}>
          The Website is only available for use in Nigeria. We make no representation that Wealthbuddy is appropriate or 
          available for use outside Nigeria. Similarly, we make no representations that accessing Wealthbuddy 
          from locations outside Nigeria is legal or permissible by local law. If you access Wealthbuddy from areas 
          outside of Nigeria, you do so at your own risk and you are solely responsible for compliance with local laws.
          </p>
        </div>

        <br /><br />

        <div>
          <h2 className="legal_header">User Registration</h2>
          <p className="legal_text" style={{width:"100%"}}>
          Wealthbuddy is an online savings and investment platform. 
          In order to access certain features of the Website, you must register to create an account
          ("User Account"). When you register, you will be asked to provide information
          (such as your date of birth and your Bank Verification Number), 
          as well as your current residential address, and/or any other KYC requirement as may be 
          prescribed by the Central Bank of Nigeria or Securities and Exchange Commissions from time to time, 
          for the opening of a Wealthbuddy account. These information shall be used for verification purposes. 
          You will also be asked to choose a password, which you will be required to use to access your 
          User Account. Wealthbuddy has physical, electronic and procedural safeguards that comply with 
          regulatory standards to guard Users' and Clients' non-public personal information (see Privacy Policy). 
          You are responsible for safeguarding your password and other User Account information.
          You agree not to disclose your password to any third party and you will notify Wealthbuddy 
          immediately if your password is lost or stolen or if you suspect any unauthorized use of your User Account. 
          As a User you agree that you shall be solely responsible for any activities or actions under your User Account, 
          whether or not you have authorized such activities or actions. You agree that the information you provide to us on
          account registration through our website or mobile applications will be true, accurate, current, and complete. 
          In order to ensure that we are able to provide communications to you electronically, 
          you must notify us of any change in your email address and your mobile device number or 
          other text message address by updating your profile on the Wealthbuddy app or website
        </p>
        </div>

        <br /><br />
        
        <div>
        <h2 className="legal_header">Notifications & Consent to use Electronic Signatures</h2>
        <p className="legal_text" style={{width:"100%"}}>
        By creating a User Account, you automatically sign up for various types of alerts via e-mail 
        and mobile notification. We never include your password in these communications, 
        but we may include your name, or email address and information about your portfolio(s) 
        if you are a Client. Anyone with access to your e-mail or mobile device will be able to view these alerts. 
        You may unsubscribe from marketing oriented emails at any time.
        To the extent permitted by applicable law, you consent to use electronic signatures and to 
        electronically receive all records, notices, statements, communications, and other items for all 
        services provided to you under these Terms and in connection with your relationship with us 
        (collectively, "Communications") that we may otherwise be required to send or provide you in paper 
        form (e.g., by mail)

        </p>
        </div>

        <br /><br />

        <div>
        <h2 className="legal_header">User Authorization</h2>
        <p className="legal_text" style={{width:"100%"}}>
        By using Wealthbuddy and providing User Information to us, you automatically authorize us to obtain, 
        directly or indirectly through our third-party service providers and without any time limit or the 
        requirement to pay any fees, information about you and your Bank Account from the financial 
        institution holding your Bank Account and other third-party websites and databases as necessary to 
        provide services to you. For purposes of such authorization, you hereby grant Wealthbuddy and our 
        third-party service providers a limited power of attorney, and you hereby appoint Wealthbuddy and our
        third-party service providers as your true and lawful attorney-in-fact and agent, with full power of 
        substitution and re-substitution, for you and in your name, place, and stead, in any and all capacities,
        to access third-party websites, servers, and documents; retrieve information; and use your 
        User Information, all as described above, with the full power and authority to do and perform each and 
        every act and thing requisite and necessary to be done in connection with such activities, as fully to
        all intents and purposes as you might or could do in person.
        <span><b>YOU ACKNOWLEDGE AND AGREE THAT WHEN WEALTHBUDDY OR OUR THIRD-PARTY SERVICE PROVIDERS ACCESS AND RETRIEVE 
        INFORMATION FROM SUCH THIRD-PARTY WEBSITES, WEALTHBUDDY AND OUR THIRD-PARTY SERVICE PROVIDERS ARE ACTING 
        AS YOUR AGENT, AND NOT THE AGENT OR ON BEHALF OF THE THIRD PARTY. 
        </b></span>
        You agree that other third parties shall be entitled to rely on the foregoing authorization,
        agency, and power of attorney granted by you. You understand and agree that the Services are 
        not endorsed or sponsored by any third-party account providers accessible through the Services. 
        We make no effort to review information obtained from the financial institution holding your
        Bank Account and other third-party websites and databases for any purpose, including, but not
        limited to, accuracy, legality, or non-infringement. As between Wealthbuddy and our 
        third-party service providers, Wealthbuddy owns your confidential User Information.
        The information you provide us is subject to our Privacy Policy.


        </p>
        </div>


        <br /><br />

        <div>
        <h2 className="legal_header">Intellectual Property Rights</h2>
        <p className="legal_text" style={{width:"100%"}}>
        Unless otherwise indicated, the Website is our proprietary property and all source code, 
        databases, functionality, software, Website designs, audio, video, text, photographs, 
        and graphics on the Website (collectively, the “Content”) and the trademarks, service marks,
        and logos contained therein (the “Marks”) are owned or controlled by us or licensed to us, 
        and are protected by copyright and trademark laws and various other intellectual property rights 
        and unfair competition laws of the Federal Republic of Nigeria, foreign jurisdictions, and international
        conventions.The Content and the Marks are provided on the Website “AS IS” for your information and 
        personal use only. Except as expressly provided in these Terms , no part of the Website and no 
        Content or Marks may be copied, reproduced, aggregated, republished, uploaded, posted, 
        publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise 
        exploited for any commercial purpose whatsoever, without our express prior written permission.
        Provided that you are eligible to use the Website, you are granted a limited license to access 
        and use the Website and to download or print a copy of any portion of the Content to which you 
        have properly gained access solely for your personal, non-commercial use. We reserve all rights 
        not expressly granted to you in and to the Website, the Content and the Marks.


        </p>

        </div>


        <br /><br />

        <div className="de"> 
        <h2 className="legal_header">User Representations</h2>
        <p className="legal_text" style={{width:"100%"}}>
        By using the Website, you represent and warrant that: <br />
        <ol style={{listStyleType:"lower-roman"}}>
          <li> All registration information you submit will be true, accurate, current, and complete; </li>
          <li> You will maintain the accuracy of such information and promptly update such registration information as necessary;</li>
          <li> You have the legal capacity and you agree to comply with these Terms;</li>
          <li> You are not under the age of 18</li>
          <li> You will not access the Website through automated or non-human means, whether through a bot, script, or otherwise;</li>
          <li> You will not use the Website for any illegal or unauthorized purpose;</li>
          <li> Your use of the Website will not violate any applicable law or regulation.</li>
          <br />
          If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to suspend or terminate your account and refuse any and all current or future use of the Website (or any portion thereof).
        </ol>

        </p>
        </div>

        <br /><br />

        <div className="de">
        <h2 className="legal_header">Prohibited Activities</h2>
        <p className="legal_text" style={{width:"100%"}}>
        You may not access or use the Website for any purpose other than that for which we make the Website available. The Website may not be used in connection with any commercial endeavors except those that are specifically endorsed or approved by us. As a user of the Website, you agree not to:<br />
        <ol style={{listStyleType:"lower-roman"}}>
        <li>	Systematically retrieve data or other content from the Website to create or compile, directly or indirectly, a collection, compilation, database, or directory without written permission from us.</li>
        <li>	Make any unauthorized use of the Website, including collecting usernames and/or email addresses of users by electronic or other means for the purpose of sending unsolicited email, or creating user accounts by automated means or under false pretenses.</li>
        <li>	Use the Website to advertise or offer to sell goods and services.</li>
        <li>  Circumvent, disable, or otherwise interfere with security-related features of the Website, including features that prevent or restrict the use or copying of any Content or enforce limitations on the use of the Website and/or the Content contained therein.</li>
        <li>	Engage in unauthorized framing of or linking to the Website.</li>
        <li>	Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords;</li>
        <li>	Make improper use of our support services or submit false reports of abuse or misconduct.</li>
        <li>	Engage in any automated use of the system, such as using scripts to send comments or messages, or using any data mining, robots, or similar data gathering and extraction tools.</li>
        <li>	Interfere with, disrupt, or create an undue burden on the Website or the networks or services connected to the Website.</li>
        <li>	Attempt to impersonate another user or person or use the username of another user.</li>
        <li>	Sell or otherwise transfer your profile.</li>
        <li>	Use any information obtained from the Website in order to harass, abuse, or harm another person.</li>
        <li>	Use the Website as part of any effort to compete with us or otherwise use the Website and/or the Content for any revenue-generating endeavor or commercial enterprise.</li>
        <li>	Decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up a part of the Website.</li>
        <li>	Attempt to bypass any measures of the Website designed to prevent or restrict access to the Website, or any portion of the Website.</li>
        <li>	Harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion of the Website to you.</li>
        <li>	Delete the copyright or other proprietary rights notice from any Content.</li>
        <li>	Copy or adapt the Website’s software, including but not limited to Flash, PHP, HTML, JavaScript, or other code.</li>
        <li> 	Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material, including excessive use of capital letters and spamming (continuous posting of repetitive text), that interferes with any party’s uninterrupted use and enjoyment of the Website or modifies, impairs, disrupts, alters, or interferes with the use, features, functions, operation, or maintenance of the Website.</li>
        <li>	Upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active information collection or transmission mechanism, including without limitation, clear graphics interchange formats (“gifs”), 1×1 pixels, web bugs, cookies, or other similar devices (sometimes referred to as “spyware” or “passive collection mechanisms” or “pcms”).</li>
        <li>	Except as may be the result of standard search engine or Internet browser usage, use, launch, develop, or distribute any automated system, including without limitation, any spider, robot, cheat utility, scraper, or offline reader that accesses the Website, or using or launching any unauthorized script or other software.</li>
        <li>	Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Website.</li>
        <li>	Use the Website in a manner inconsistent with any applicable laws or regulations.</li>

        </ol>
        </p>

        </div>

        <br /><br />

        <div className="de">
        <h2 className="legal_header">Mobile Application Licence</h2>
        <p className="legal_text" style={{width:"100%"}}>
        If you access the Website via a mobile application, then we grant you a revocable, non-exclusive, non-transferable, limited right to install and use the mobile application on wireless electronic devices owned or controlled by you, and to access and use the mobile application on such devices strictly in accordance with the terms and conditions of this mobile application license contained in these Terms.
          You shall not:<br />
          <ol style={{listStyleType:"lower-roman"}}>
            <li> Decompile, reverse engineer, disassemble, attempt to derive the source code of, or decrypt the application; </li>
            <li> Make any modification, adaptation, improvement, enhancement, translation, or derivative work from the application;</li>
            <li> Violate any applicable laws, rules, or regulations in connection with your access or use of the application;</li>
            <li> Remove, alter, or obscure any proprietary notice (including any notice of copyright or trademark) posted by us or the licensors of the application;</li>
            <li> Use the application for any revenue generating endeavor, commercial enterprise, or other purpose for which it is not designed or intended;</li>
            <li> Make the application available over a network or other environment permitting access or use by multiple devices or users at the same time;</li>
            <li> Use the application for creating a product, service, or software that is, directly or indirectly, competitive with or in any way a substitute for the application;</li>
            <li> Use the application to send automated queries to any Website or to send any unsolicited commercial e-mail;</li>
            <li> Use any proprietary information or any of our interfaces or our other intellectual property in the design, development, manufacture, licensing, or distribution of any applications, accessories, or devices for use with the application.</li>
        </ol>

        </p>
        </div>
        <br /><br />

        <div>
        <h2 className="legal_header">Privacy Policy </h2>
        <p className="legal_text" style={{width:"100%"}}>
        We care about data privacy and security. All information we collect on this Website is subject 
        to our <Link to="">Privacy Policy</Link>. By using the Website, you consent to all actions taken by us with respect 
        to your information in compliance with the Privacy Policy.
        </p>

        </div>


        <br /><br />

        <div>
        <h2 className="legal_header">Modification & Interruption </h2>
        <p className="legal_text" style={{width:"100%"}}>
        We reserve the right to change, modify, or remove the contents of the Website at any time or 
        for any reason at our sole discretion without notice. However, we have no obligation to update 
        any information on our Website. We also reserve the right to modify or discontinue all or part 
        of the Website without notice at any time.
        We will not be liable to you or any third party for any modification, price (including interest rate)
        changes, suspension, or discontinuance of the Website. We cannot guarantee the Website will be 
        available at all times. We may experience hardware, software, or other problems or need to perform 
        maintenance related to the Website, resulting in interruptions, delays, or errors. We reserve the
        right to change, revise, update, suspend, discontinue, or otherwise modify the Website at any time
        or for any reason without notice to you. You agree that we have no liability whatsoever for any loss,
        damage, or inconvenience caused by your inability to access or use the Website during any downtime 
        or discontinuance of the Website. Nothing in these Terms will be construed to obligate us to maintain 
        and support the Website or to supply any corrections, updates, or releases in connection therewith.

        </p>
        </div>


        <br /><br />

        <div className="de">
        <h2 className="legal_header">Idenmification </h2>
        <p className="legal_text" style={{width:"100%"}}>
        You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, 
        and all of our respective officers, agents, partners, and employees, from and against any loss, 
        damage, liability, claim, or demand, including reasonable attorneys’ fees and expenses, made by 
        any third party due to or arising out of:<br />
        <ol style={{listStyleType:"number"}}>
          <li> Breach of these Terms; </li>
          <li> Any breach of your representations and warranties set forth in these Terms; </li>
          <li> Any fraudulent activity carried out by you; </li>
          <li> Any overt harmful act toward any other user of the Website with whom you connected via the 
               Website. Notwithstanding the foregoing, we reserve the right, at your expense, to assume the 
               exclusive defense and control of any matter for which you are required to indemnify us, 
               and you agree to cooperate, at your expense, with our defense of such claims. We will use 
               reasonable efforts to notify you of any such claim, action, or proceeding which is subject
               to this indemnification upon becoming aware of it. </li>
        </ol>

        </p>
        </div>



        <br /><br />

        <div>

        <h2 className="legal_header">Electronic Communications, Transaction and Signature </h2>
        <p className="legal_text" style={{width:"100%"}}>
        By creating a User Account, you automatically sign up for various types of alerts via e-mail 
        and mobile notification. We never include your password in these communications, but we may 
        include your name, or email address and information about your portfolio(s) if you are a Client.
        Anyone with access to your e-mail or mobile device will be able to view these alerts. 
        You may unsubscribe from marketing oriented emails at any time. Visiting the Website, sending us emails, 
        and completing online forms constitute electronic communications. You consent to receive electronic 
        communications, and you agree that all agreements, notices, disclosures, and other communications 
        we provide to you electronically, via email and on the Website, satisfy any legal requirement that 
        such communication be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS,
        AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS 
        INITIATED OR COMPLETED BY US OR VIA THE WEBSITE. You hereby waive any rights or requirements under 
        any statutes, regulations, rules, ordinances, or other laws in any jurisdiction which require an 
        original signature or delivery or retention of non-electronic records, or to payments or the granting 
        of credits by any means other than electronic means. These Terms shall remain in full force and 
        effect while you use the Website. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS, 
        WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND 
        USE OF THE WEBSITE (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR 
        NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT 
        CONTAINED IN THESE TERMS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR 
        PARTICIPATION IN THE WEBSITE OR DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED 
        AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION. If we terminate or suspend your account for 
        any reason, you are prohibited from registering and creating a new account under your name, a fake 
        or borrowed name, or the name of any third party, even if you may be acting on behalf of the third party.
        In addition to terminating or suspending your account, we reserve the right to take appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress.


        </p>

        </div>


        <br /><br />

        <div className="de"> 
        <h2 className="legal_header">Disclaimer</h2>
        <p className="legal_text" style={{width:"100%"}}>
        Wealthbuddy cannot be held responsible for any financial insights or recommendations provided to Users.<br />
        <ol style={{listStyleType:"lower-alpha"}}>
          <li>	For a User who is not a Client: <br /> 
                You understand and acknowledge that the investment results you 
                could obtain from investment information and financial insights provided by Wealthbuddy 
                cannot be guaranteed and that Wealthbuddy cannot be held responsible. 
                All investments entail a risk of loss and that you may lose money. 
                Investment management services may be offered to individuals who become clients,
                at the sole discretion of Wealthbuddy. Your election to engage our savings and investment 
                services are subject to your explicit enrollment and acceptance of this Terms. 
                You agree and understand that your use of Wealthbuddy is for educational purposes only and is 
                not intended to provide legal, tax or financial planning advice. You agree as a User that you 
                are responsible for your own investment research and investment decisions, that Wealthbuddy 
                is only one of many tools you may use as part of a comprehensive investment education process,
                that you should not and will not rely on Wealthbuddy as the primary basis of your investment 
                decisions and, except as otherwise provided for herein, Wealthbuddy will not be liable for 
                decisions/actions you take or authorize third parties to take on your behalf based on
                information you receive as a User of Wealthbuddy or information you otherwise see on 
                our website.
          </li>
          <li>	Disclaimer Warranties <br />
               THE WEBSITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. 
               YOU AGREE THAT YOUR USE OF THE WEBSITE AND OUR SERVICES WILL BE AT YOUR SOLE RISK. 
               TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, 
               IN CONNECTION WITH THE WEBSITE AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, 
               THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, 
               AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR 
               COMPLETENESS OF THE WEBSITE’S CONTENT OR THE CONTENT OF ANY WEBSITES LINKED TO THE WEBSITE 
               AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY 
               <ol style={{listStyleType:"number"}}>
                  <li>ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, </li>
                  <li>PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, 
                      RESULTING FROM YOUR ACCESS TO AND USE OF THE WEBSITE,</li>
                  <li>ANY UNAUTHORIZED ACCESS TO OR USE 
                      OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION 
                      STORED THEREIN,</li>
                  <li> ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE WEBSITE, </li>
                  <li>ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE WEBSITE BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE WEBSITE. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE WEBSITE, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.</li>
              </ol>
          </li>
        </ol>
        </p>
        </div>

        <br /><br />

        <div>
        <h2 className="legal_header">Limitation of Liability</h2>
        <p className="legal_text" style={{width:"100%"}}>
        IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY
        FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES,
        INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE WEBSITE,
        EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
        </p>
        </div>

        <br /><br />
        
        <div>
        <h2 className="legal_header">Governing Law</h2>
        <p className="legal_text" style={{width:"100%"}}>
        These Terms and your use of the Website are governed by and construed in accordance with the laws 
        of the Federal Republic of Nigeria. 
        At Company's sole discretion, it may require You to submit any disputes arising from these 
        Terms or use of the Website, including disputes arising from or concerning their interpretation, 
        violation, invalidity, non-performance, or termination, to final and binding arbitration under 
        the Arbitration and Conciliation Act or any other available dispute resolution mechanism.


        </p>
        </div>

        <br /><br />

        <div>
        <h2 className="legal_header">Miscellanous</h2>
        <p className="legal_text" style={{width:"100%"}}>
        These Terms, our Privacy Policy and any policies or operating rules posted by us on the Website 
        constitute the entire agreement and understanding between you and us. Our failure to exercise or 
        enforce any right or provision of these Terms shall not operate as a waiver of such right or provision.
        These Terms operate to the fullest extent permissible by law. We may assign any or all of our rights 
        and obligations to others at any time. We shall not be responsible or liable for any loss, damage, 
        delay, or failure to act caused by any cause beyond our reasonable control.
        If any provision or part of a provision of these Terms is determined to be unlawful, void, or 
        unenforceable, that provision or part of the provision is deemed severable from these Terms and does 
        not affect the validity and enforceability of any remaining provisions. There is no joint venture, 
        partnership, employment or agency relationship created between you and us as a result of these 
        Terms or use of the Website. You agree that these Terms will not be construed against us by virtue 
        of having drafted them. You hereby waive any and all defenses you may have based on the electronic
        form of these Terms and the lack of signing by the parties hereto to execute these Terms.


        </p>
        </div>

        <br /><br />

        <div>
        <h2 className="legal_header">Contact Us</h2>
        <p className="legal_text" style={{width:"100%"}}>
        In order to resolve a complaint regarding the Website or to receive further information 
        regarding use of the Website, please contact us at:
        Meristem Wealth Management Limited <br />
        <b>No 3 Norman Williams Street, Ikoyi, Lagos</b><br />
        <b>01 448 5990</b><br />
        <b>info@Wealthbuddy.ng</b> <br />


        </p><br /><br />
        This document constitutes Wealthbuddy's complete Terms of Service for Wealthbuddy and related services. If you have questions about these Terms of Services or about Wealthbuddy or content thereon, please contact Wealthbuddy at <b>info@wealthbuddy.ng</b>

        </div>

        {/* <div>
        <h2 className="legal_header">Contact Us</h2>
        <p className="legal_text" style={{width:"100%"}}>
        In order to resolve a complaint regarding the Website or to receive further information 
        regarding use of the Website, please contact us at: <br /><br />
        Meristem Wealth Management Limited <br />
        No 3 Norman Williams Street, Ikoyi, Lagos<br />
        01 448 5990<br />
        info@Wealthbuddy.ng <br />



        </p>
        </div> */}


        </div>
        {/* <div style={{ margin: '0 auto' }}>
          <img src={file} alt="" className="file_img" />
        </div> */}
      



      </div>


    
  );
}
export default Terms_Conditions;
