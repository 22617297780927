import React from "react";

export default function Footer() {
    return (

        <footer className="buddy-standard--section buddy-section--100 standard--bg">
            <div className="container">
                <div className="buddy-footer--column">
                    <div className="buddy-footer--columns">
                        <div className="footer-column">
                            <ul className="buddy-footer--menu">
                                <li>Products</li>
                                <li>Privacy</li>
                                <li>Terms & Conditions </li>
                            </ul>
                        </div>
                        {/* <div className="footer-column">
                            <ul className="buddy-footer--menu">
                                <li>Terms & Conditions </li>
                            </ul>
                        </div> */}
                    
                        <div className="footer-column column-is--two">
                            <div className="buddy-cta buddy-cta--lg">Need help?</div>
                           
                        </div>
                    </div>
                    <div className="buddy-copyright--section">
                        
                       <p>Wealthbuddy by Meristem</p>
                    </div>
                </div>
            </div>
        </footer>
    );
}
