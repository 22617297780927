import React from 'react';
import WebScreen7 from '../../../assets/img/web-laptop.png';
import {
  webSolutionLeafLeft,
  webSolutionLeafLeftTwo,
  mobileSolutionsCoinLeftTwo,
} from '../assets/imports';

export default function WebSolutions() {
  return (
    <div className="buddy-standard--section buddy-section--100 padding-top--small">
      <div className="container">
        <div className="buddy-section">
          <div className="buddy-center--section">
            <div className="buddy-section--100">
              <div className="buddy-screens--wrap web-screen--wrap">
                <img
                  className="buddy-single--screen web_lap"
                  src={WebScreen7}
                  alt={'Buddy Web Solutions'}
                />
              </div>
            </div>
            <div className="buddy-section--100">
              <div className="buddy-standard--wrap">
                <h1 className="text-heading text-semi--bold heading-spacer">
                  Web solution
                </h1>
                <p className="text-subheading">
                  Based on years of experience growing wealth, Wealthbuddy makes
                  it possible to define exactly what you want the future to be.
                  You have come this far, Let's take you farther.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="web-solution--illustration">
        <span
          className="web-leaf--left"
          dangerouslySetInnerHTML={{ __html: webSolutionLeafLeft }}
        ></span>
        <span
          className="web-leaf--left-two"
          dangerouslySetInnerHTML={{ __html: webSolutionLeafLeftTwo }}
        ></span>
        <span
          className="web-coin-left animate-coin"
          dangerouslySetInnerHTML={{ __html: mobileSolutionsCoinLeftTwo }}
        ></span>
        <span
          className="web-coin-left-two animate-coin"
          dangerouslySetInnerHTML={{ __html: mobileSolutionsCoinLeftTwo }}
        ></span>
      </div>

      <div className="web-coin--illustration">
        <span
          className="web-coin-right animate-coin"
          dangerouslySetInnerHTML={{ __html: mobileSolutionsCoinLeftTwo }}
        ></span>
        <span
          className="web-coin-right-two animate-coin"
          dangerouslySetInnerHTML={{ __html: mobileSolutionsCoinLeftTwo }}
        ></span>
        <span
          className="web-coin-right-three animate-coin"
          dangerouslySetInnerHTML={{ __html: mobileSolutionsCoinLeftTwo }}
        ></span>
        <span
          className="web-coin-right-four animate-coin"
          dangerouslySetInnerHTML={{ __html: mobileSolutionsCoinLeftTwo }}
        ></span>
      </div>
    </div>
  );
}
