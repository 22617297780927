import React from 'react';
import enable from '../../../assets/img/money_tree.svg';
import grow from '../../../assets/img/money_bag.svg';
import mature from '../../../assets/img/money_list.svg';
import {
  belowFoldLeafLeft,
  belowFoldLeafRight,
  belowFoldCoinLeft,
} from '../assets/imports';

export default function Guide() {
  return (
    <div className="buddy-standard--section buddy-section--100 overflow-visible">
      <div className="container">
        <div className="buddy-section">
          <div className="buddy-section--50">
            <div className="buddy-standard--wrap">
              <h1 className="text-heading text-semi--bold heading-spacer">
                Your one stop<br /> Financial partner!
              </h1>
              <p className="text-subheading">
                Do you need to save more, reduce debt, invest or make progress
                towards long-term financial targets. We will help you evaluate
                financial situations and needs, guide and engage you to achieve
                your financial goals.{' '}
              </p>
            </div>
          </div>
          <div className="buddy-section--50">
            <div className="buddy-steps--cta">
              <div className="buddy-step">
                <div className="buddy-step--icon">
                  <span className="step-icon">
                    <img
                      src={mature}
                      alt={'Wealth Buddy Steps'}
                      style={{ height: 73 }}
                    />
                  </span>
                </div>
                <div className="buddy-step--text">
                  <p>
                    Enable clients to onboard seamlessly within not more than
                   2 minutes
                  </p>
                </div>
              </div>
              <div className="buddy-step">
                <div className="buddy-step--icon">
                  <span className="step-icon">
                    <img
                      src={enable}
                      alt={'Wealth Buddy Steps'}
                      style={{ height: 73 }}
                    />
                  </span>
                </div>
                <div className="buddy-step--text">
                  <p>
                    Gain access to local and foreign denominated investments to
                    grow and protect your wealth
                  </p>
                </div>
              </div>
              <div className="buddy-step">
                <div className="buddy-step--icon">
                  <span className="step-icon">
                    <img
                      src={grow}
                      alt={'Wealth Buddy Steps'}
                      style={{ height: 73 }}
                    />
                  </span>
                </div>
                <div className="buddy-step--text">
                  <p>
                    Save, Invest, and Pay bills without even thinking about it.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="below-fold--illustrations">
        <span
          className="leaf-left--below---fold"
          dangerouslySetInnerHTML={{ __html: belowFoldLeafLeft }}
        ></span>
        <span
          className="leaf-right--below---fold"
          dangerouslySetInnerHTML={{ __html: belowFoldLeafRight }}
        ></span>
        <span
          className="coin-left--below---fold animate-coin"
          dangerouslySetInnerHTML={{ __html: belowFoldCoinLeft }}
        ></span>
      </div>
    </div>
  );
}
