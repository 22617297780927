import React, { useEffect } from 'react';

const MobileMenu = () => {
  useEffect(() => {
    setTimeout(() => {
      document.querySelector('.buddy-mobile--menu').classList.add('show-menu');
    }, 30);

    return () => {
      document
        .querySelector('.buddy-mobile--menu')
        .classList.remove('show-menu');
    };
  }, []);
  return (
    <div className="buddy-mobile--menu">
      <ul className="buddy-mobile--items">
        {/* <li className="mobile-item">
          <Link to="/">Our Products</Link>
        </li>
        <li className="mobile-item">
          <Link to="/">About</Link>
        </li>
        <li className="mobile-item">
          <Link to="/"> Contact</Link>
        </li> */}
        <li className="mobile-item">
          <a href="http://app.wealthbuddy.ng/">Login</a>
        </li>
        <li className="mobile-item">
          <a href="http://app.wealthbuddy.ng/">Get Started </a>
        </li>
      </ul>
    </div>
  );
};

export default MobileMenu;
